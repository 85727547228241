<template>
  <b-row
    id="dePara"
    no-gutters
  >
    <b-col class="pr-1">
      <b-form-group
        :label="dataInicialLabel"
        label-for="dataInicial"
      >
        <validation-provider
          #default="{ errors }"
          :name="dataInicialLabel"
          :rules="required ? required : ''"
        >
          <data-input
            id="dataInicial"
            :ref="'dataInicio'"
            v-model="valueInner.dataInicio"
            class="start-date"
            :invalid="invalid"
            :class="{'is-invalid' : errors.length > 0}"
            :show-icon="false"
            :placeholder="dataInicialPlaceholder"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        :label="dataFinalLabel"
        label-for="dataFinal"
      >
        <validation-provider
          #default="{ errors }"
          :name="dataFinalLabel"
          :rules="required && requiredEnd ? 'required' : '' "
        >
          <data-input
            id="dataFinal"
            :ref="'dataFim'"
            v-model="valueInner.dataFim"
            class="end-date"
            :invalid="invalid"
            :class="{'is-invalid' : errors.length > 0}"
            :show-icon="false"
            :disabled="blockedEnd && !valueInner.dataInicio"
            :placeholder="dataFinalPlaceholder"
            :disabled-dates="disabledDates"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<style lang="scss">
#dePara {
  .start-date input.form-control {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .end-date input.form-control {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}
</style>

<script>
/* eslint-disable func-names */

import Vue from 'vue'
import moment from 'moment'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'DePara',
  components: {
    ValidationProvider,
    DataInput: () => import('@pilar/components/data-input/DataInput.vue'),
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    requiredEnd: {
      type: Boolean,
      default: false,
    },
    blockedEnd: {
      type: Boolean,
      default: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
    dataInicialLabel: {
      type: String,
      default: 'Data inicial',
    },
    dataFinalLabel: {
      type: String,
      default: 'Data final',
    },
    dataInicialPlaceholder: {
      type: String,
      default: 'Data inicial',
    },
    dataFinalPlaceholder: {
      type: String,
      default: 'Data final',
    },
  },
  data() {
    return {
      valueInner: {
        dataInicio: null,
        dataFim: null,
      },
      disabledDates: {},
    }
  },
  watch: {
    value: {
      deep: true,
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.valueInner = value
        }
      },
    },
    'valueInner.dataFim': function (value, oldValue) {
      if (value && !this.valueInner.dataFim) {
        this.invalidDataInicio = true
      } else {
        this.invalidDataInicio = false
      }

      if (value !== oldValue) {
        this.$emit('input', { dataInicio: this.valueInner.dataInicio, dataFim: value })
      }
    },
    'valueInner.dataInicio': function (value, oldValue) {
      const date = moment(value, 'DD/MM/YYYY').toDate()
      Vue.set(this.disabledDates, 'to', date)
      if (!value && this.valueInner.dataFim) {
        this.valueInner.dataFim = null
      }

      if (value !== oldValue) {
        this.$emit('input', { dataInicio: value, dataFim: this.valueInner.dataFim })
      }
    },
  },
  focus() {
    this.$refs.dataInicio.$el.focus()
  },
  created() {
    this.valueInner = this.value
  },
}
</script>
